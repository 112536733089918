<template>
  <span>
    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @row-click="handleEvent('row-click', $event)"
    >
      <DxColumn
        v-for="(item, index) in dataColumns"
        :key="index"
        :data-field="item.field"
        :caption="item.caption"
        :cell-template="item.template"
      />

      <DxPaging :page-size="10" />
      <DxPager
        :visible="true"
        :allowed-page-sizes="[10, 20, 50, 100]"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />

      <DxColumnChooser :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel location="before" :visible="true" />

      <DxToolbar>
        <DxItem location="before" name="columnChooserButton" />
        <DxItem location="before" name="searchPanel" />
        <DxItem name="groupPanel" />
      </DxToolbar>
    </DxDataGrid>
  </span>
</template>

<script>
export default {
  name: 'JobOrderPreviousJobsPage',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
  },
  watch: {
    dataSource(items) {
      //
    },
  },
  data: () => ({
    dataColumns: [{ caption: 'Ticket No.', field: 'ticket_no' }],
  }),
  mounted() {
    //
  },
  methods: {
    handleEvent(action, event) {
      if (action === 'row-click') {
        const url = `/pages/services-tools/job-order/view?id=${event.key}`;
        window.open(url, '_blank');
      }
    },
  },
};
</script>

<style></style>
